import { ShopProductDetails } from '../../lib/api/models/shop';
import { hesselViewModels } from '../../lib/view-models';
import { DateStyle, formatDate } from './date.helper';
import { formatPrice } from './price.helper';

export function getPdpPageTitle(product: hesselViewModels.ProductDetails, colorName: string): string {
    if (product.metaTitle && product.metaTitle.length > 0) {
        return product.metaTitle;
    }

    if (product.availability === 'InStock') {
        return `Ny på lager: ${product.brand}  ${product.variantTitle} i ${colorName} | ${formatPrice(
            product.purchaseTypes?.cash.basePrice
        )} kr. | Hessel`;
    }

    if (product.availability === 'Used') {
        return `Brugt ${product.brand} ${product.modelTitle ?? ''} ${product.name} i ${colorName} | ${formatPrice(
            product.purchaseTypes?.cash.basePrice
        )} kr. | Hessel`;
    }

    if (product.vehicleType === 'Van' && product.availability === 'Engros') {
        return `Engros varebil ${product.brand} ${product.modelTitle}  ${product.name} i ${colorName} | ${formatPrice(
            product.purchaseTypes?.cash.basePrice
        )} | Hessel`;
    }

    return `Ny bil: ${product.brand} ${product.variantTitle} i ${colorName} | ${formatPrice(product.purchaseTypes?.cash.basePrice)} kr. | Hessel`;
}

export function getPdpMetaDescription(product: hesselViewModels.ProductDetails, colorName: string): string {
    if (product.metaDescription && product.metaDescription.length > 0) {
        return product.metaDescription;
    }

    if (product.availability === 'InStock') {
        return `Vi har netop fået denne spritnye ${product.brand} ${product.variantTitle} i ${colorName}. Skynd dig at booke en prøvetur eller kom ind i afdelingen og se den i dag`;
    }

    const firstMonthAndYearOfReg = product.specifications.firstMonthAndYearOfRegistration || formatDate(new Date(2022, 1, 1), DateStyle.yyyy_mm_dd);

    if (product.availability === 'Used') {
        return `Klik og læs mere om denne ${product.brand} ${
            product.variantTitle
        } fra ${firstMonthAndYearOfReg} i ${colorName}. Prisen er ${formatPrice(
            product.purchaseTypes.cash.basePrice
        )} og den står klar til omgående levering hos Ejner Hessel`;
    }

    if (product.vehicleType === 'Van' && product.availability === 'Engros') {
        return `Vi har netop fået denne brugte ${product.brand} ${product.variantTitle} i ${colorName} fra ${firstMonthAndYearOfReg} . Prisen er ${product.purchaseTypes.cash.basePrice} og det er en afhentningspris. Kom ind i afdelingen og se den i dag.`;
    }

    return `Vi har netop fået denne spritnye ${product.brand} ${product.variantTitle} i ${colorName}. Skynd dig at booke en prøvetur eller kom ind i afdelingen og se den i dag`;
}

export const getShopPdpMetaTitle = (product: ShopProductDetails): string => {
    return product.metaTitle ?? `Køb ${product.name} ${product.color && product.color.length > 0 ? ` | ${product.color}` : ''} | Hessel`;
};

export const getShopPdpMetaDescription = (product: ShopProductDetails): string => {
    const description = `${product.description.split('.')[0]}.`;
    return (
        product.metaDescription ??
        `${description.length > 120 ? `${description.substring(0, 120)}...` : description} | Shop hos Hessel | Hurtig levering på 1-3 dage`
    );
};
